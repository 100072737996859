/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EducationalLevel from "@/models/EducationalLevel";
import MedicalLocation from "@/models/MedicalLocation";
import Patient from "@/models/Patient";
import State from "@/models/State";
import Worker from "@/models/Worker";

export function mutLoading(state: any, loading: boolean): void {
  state.loading = loading;
}
export function mutReqRange(state: any, reqrange: any): void {
  state.reqrange = reqrange;
}
export function mutHaveCard(state: any, have: boolean): void {
  state.havecard = have;
}
export function mutepayCard(state: any, have: boolean): void {
  state.epaycard = have;
}
export function mutunreadDoc(state: any, have: boolean): void {
  state.unreadDoc = have;
}
export function mutcountUnred(state: any, have: number): void {
  state.countUnread = have;
}

// --
export function mutSetStates(state: any, states: State[]): void {
  state.states = states;
}

export function mutLoadingStates(state: any, loading: boolean): void {
  state.loadingStates = loading;
}
export function mutHeaders(state: any, headers: []): void {
  state.headersPatients = headers;
}
export function mutTotalPatient(state: any, val: number): void {
  state.totalpatient = val;
}
// --

export function mutSetEducationalLevels(
  state: any,
  educationLevels: EducationalLevel[]
): void {
  state.educationLevels = educationLevels;
}
export function mutLoadingEducationLevels(state: any, loading: boolean): void {
  state.loadingEducationLevels = loading;
}
// --

export function mutSetMedicalLocations(
  state: any,
  medicalLocations: MedicalLocation[]
): void {
  state.medicalLocations = medicalLocations;
}

export function mutLoadingMedicalLocations(state: any, loading: boolean): void {
  state.loadingMedicalLocations = loading;
}
// --

export function mutSetPatients(state: any, patients: Patient[]): void {
  state.patients = patients;
}

export function mutLoadingPatiens(state: any, loading: boolean): void {
  state.loadingPatiens = loading;
}
// --

export function mutSetDoctors(state: any, doctors: Worker[]): void {
  state.doctors = doctors;
}
export function mutSetAgents(state: any, agents: Worker[]): void {
  state.agents = agents;
}

export function mutLoadingDoctors(state: any, loading: boolean): void {
  state.loadingDoctors = loading;
}
// --

export function mutShowDetails(state: any, showDetails: boolean): void {
  state.showDetails = showDetails;
}

export function mutPatientDetails(state: any, details: any): void {
  state.patientDetail = details;
}
export function mutPatientQuotes(state: any, quotes: any): void {
  state.quotes = quotes;
}
export function mutSalesAccessories(state: any, values: []): void {
  state.salesAccessories = values;
}

export function mutProcedure(state: any, procedure: any): void {
  state.procedure = procedure;
}
